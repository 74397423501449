<template>
  <div>
    <Header
      titleurl="recruit"
      :iscontactTel="iscontactTel"
      v-on:closeShow="closeShow"
    ></Header>
    <div class="center">
      <div class="containers">
        <div class="rows">
          <div class="search">
            <el-input
              type="text"
              placeholder="请输入职位名称或公司"
              v-model="param.keyword"
            ></el-input>
            <img src="../../assets/img/seach.png" />
            <el-button class="searchp" @click="recruits">搜职位</el-button>
            <!-- <router-link to="/recruit/position">
              <span
                ><el-button type="danger" plain class="recruit_position"
                  >发布招聘</el-button
                ></span
              >
            </router-link> -->
            <span
              ><el-button
                type="danger"
                plain
                class="recruit_position"
                @click="getToken"
                >发布招聘</el-button
              ></span
            >
          </div>
        </div>
        <div class="rows">
          <router-link to="/">
            <span>
              <div class="position">牛豆豆</div>
            </span>
          </router-link>
          <span class="position_right"> &gt; </span>
          <el-select
            class="searchjinyingu"
            v-model="searchjinyingu"
            @change="jobrecruit()"
            placeholder="请选择"
            style="width: 12%"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="rows">
          <div class="position">职位:</div>
          <div class="every">
            <div class="everything">
              <span
                :class="{ activeposition: activeposition == '' }"
                @click="clickposition('')"
                >全部</span
              >
              <span
                v-for="item in positionlist"
                :class="{ activeposition: activeposition == item.id }"
                :key="item.id"
                @click="clickposition(item)"
                >{{ item.name }}</span
              >
            </div>
          </div>
        </div>
        <div class="rows">
          <div class="position">地点:</div>
          <div class="every">
            <div class="everything">
              <span
                v-for="item in countys"
                :class="{ activearea: activearea == item.code }"
                :key="item.code"
                @click="clickarea(item)"
                >{{ item.name }}</span
              >
            </div>
          </div>
        </div>
        <div class="rows">
          <div class="position">福利:</div>
          <div class="every">
            <div class="everything">
              <span
                v-for="item in wels"
                :key="item.code"
                :class="{ activewelfare: iswelfare(activewelfare, item.code) }"
                @click="clickwelfare(item)"
                >{{ item.name }}</span
              >
            </div>
          </div>
        </div>
        <div class="rows other">
          <div class="position rightlittle">其他:</div>
          <el-select
            placeholder="发布时间"
            v-model="param.creDateCode"
            @change="creDateCodes()"
          >
            <el-option
              v-for="item in creDates"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select
            placeholder="薪资待遇"
            v-model="param.salRangeCode"
            @change="salRangeCodes()"
          >
            <el-option
              v-for="item in sals"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select
            placeholder="学历要求"
            v-model="param.eduCode"
            @change="eduCodes()"
          >
            <el-option
              v-for="item in edus"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select
            placeholder="工作经验"
            v-model="param.workExpCode"
            @change="workExpCodes()"
          >
            <el-option
              v-for="item in workExps"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select
            placeholder="公司行业"
            v-model="param.industyCode"
            @change="industyCodes()"
          >
            <el-option value="" label="">请选择</el-option>
            <el-option
              v-for="item in indutrys"
              :key="item.code"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button @click="empty()">清空条件</el-button>
        </div>
        <div class="rows">
          <div class="position rightlittle">已选:</div>
          <el-tag
            v-for="tag in tags"
            :key="tag.name"
            closable
            @close="handleClose(tag)"
            :type="tag.type"
          >
            {{ tag.name }}
          </el-tag>
          <div class="allresult">
            共<span>{{ total }}</span
            >条结果
          </div>
        </div>
      </div>
      <div class="containers">
        <div class="rows borderdown">
          <div class="resumelist">职位</div>
          <div class="homepage">
            <span class="numbersmall" @click="numbersmall()">&lt;</span>
            <span class="redfont param.page">{{ param.pageIndex }}</span>
            <span>/</span>
            <span class="maximum">{{ maximum }}</span>
            <span class="numberbig" @click="numberbig()">&gt;</span>
          </div>
        </div>
        <!-- <div class="rows resumebegin">
				<el-checkbox>招聘方在线</el-checkbox>
				<el-checkbox>查看本地工作</el-checkbox>
				<el-checkbox>营业执照已验证</el-checkbox>
				<img class="safeinvestment" src="../../assets/img/safeinvestment.png"/>
			</div> -->
        <div class="rows resumebegin bodyheight" v-if="jobslist.length > 0">
          <div class="resumebody" v-for="item in jobslist" :key="item.id">
            <div class="body_font_first">
              <div class="turnred" @click="lookdetails(item.id)">
                <div class="place">{{ item.workCounty }}</div>
                <span class="position_line">|</span>
                <span class="position_first">{{ item.cmpName }}</span>
              </div>
              <!-- <img src="../../assets/img/recruit_liao.png"/> -->
              <div class="expect">{{ item.salary }}</div>
              <div class="ablabel">
                <label v-for="item in item.welfare.split(',')" :key="item">{{
                  item
                }}</label>
              </div>
            </div>
            <div class="body_font_second">
              <span class="company">{{ item.cmpName }}</span>
              <div class="position_second">
                <span>{{ item.positionName }}</span>
                <span>|</span>
                <span>{{ item.requires }}</span>
                <span>|</span>
                <span>{{ item.workExp }}</span>
              </div>
            </div>
            <div class="body_font_button">
              <el-button type="primary" @click="lookdetails(item.id)"
                >申请</el-button
              >
            </div>
            <div class="body_font_third">
              {{ item.createDate }}
            </div>
          </div>
        </div>
        <div class="rows resumebegin bodyheight" v-if="jobslist.length === 0">
          <div class="bodyheight-norecruit">暂无职位</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../../components/header';
import Footer from '../../components/footer';
import Utilcity from '@/utils/unilcity.js';
import { screen, getjob, datagridjobs } from '@/api/recruit';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
export default {
  name: 'Jobwant',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      positionlist: [],
      tags: [],
      total: 20,
      maximum: 10,
      cityCode: '',
      jobid:0,
      options: [
        { label: '牛豆豆二手房', value: 'secondhouse' },
        { label: '牛豆豆求职', value: 'jobwanted' },
        { label: '牛豆豆招聘', value: 'recruit' },
        { label: '牛豆豆出租房', value: 'rentinghouse' },
      ],
      searchjinyingu: 'recruit',
      param: {
        cityCode: '410100',
        countyCode: '',
        creDateCode: '',
        eduCode: '',
        industyCode: '',
        jobId: '',
        jobKey: '',
        pageIndex: 1,
        isEffective: 0,
        pageSize: 10,
        salRangeCode: '',
        welfareCodes: [],
        workExpCode: '',
        keyword: '',
      },
      uid: '230',
      creDates: [],
      edus: [],
      sals: [],
      wels: [],
      indutrys: [],
      workExps: [],
      countys: [],
      jobslist: [],
      activeposition: '',
      activearea: null,
      activewelfare: [],
      // token
      accessToken: '',
      iscontactTel: false,
    };
  },
  mounted() {
    this.accessToken =getCookieByName('access_token');
    Utilcity.$on('cityid', (message) => {
      this.cityCode = message;
      this.param.cityCode = message;
      this.getscreen();
      this.getdatagridjobs();
    });
    if (localStorage.getItem('cityid')) {
      this.cityCode = localStorage.getItem('cityid');
    }
    if(this.$route.query.citycode&&this.$route.query.citycode!=""){
      this.citycode = this.$route.query.citycode;
    }
    this.param.cityCode =this.citycode;

    if(this.$route.query.jobid&&this.$route.query.jobid!=""){
      this.param.jobid = this.$route.query.jobid;
    }

    this.getscreen();
    this.getjobs();
    this.getdatagridjobs();
  },
  methods: {
    //搜职位
    recruits() {
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    //日期
    creDateCodes() {
      const self = this;
      if (this.param.creDateCode == '') {
        this.deltag('credate');
      } else {
        this.creDates.forEach(function (item, i) {
          if (self.param.creDateCode == item.code) {
            self.addtag(item.code, item.name, 'credate');
          }
        });
      }
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    //薪资待遇
    salRangeCodes() {
      const self = this;
      if (this.param.salRangeCode == '') {
        this.deltag('sal');
      } else {
        this.sals.forEach(function (item, i) {
          if (self.param.salRangeCode == item.code) {
            self.addtag(item.code, item.name, 'sal');
          }
        });
      }
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    //学历要求
    eduCodes() {
      const self = this;
      if (this.param.eduCode == '') {
        this.deltag('edu');
      } else {
        this.edus.forEach(function (item, i) {
          if (self.param.eduCode == item.code) {
            self.addtag(item.code, item.name, 'edu');
          }
        });
      }
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    //工作经验
    workExpCodes() {
      const self = this;
      if (this.param.workExpCode == '') {
        this.deltag('work');
      } else {
        this.workExps.forEach(function (item, i) {
          if (self.param.workExpCode == item.code) {
            self.addtag(item.code, item.name, 'work');
          }
        });
      }
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    //公司行业
    industyCodes() {
      const self = this;
      if (this.param.industyCode == '') {
        this.deltag('industy');
      } else {
        this.indutrys.forEach(function (item, i) {
          if (self.param.industyCode == item.name) {
            self.addtag(item.name, item.name, 'industy');
          }
        });
      }
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    getscreen() {
      screen(this.cityCode).then((res) => {
        this.countys = res.data.result.countys;
        this.workExps = res.data.result.workExps;
        this.indutrys = res.data.result.indutrys;
        this.wels = res.data.result.wels;
        this.sals = res.data.result.sals;
        this.edus = res.data.result.edus;
        this.creDates = res.data.result.creDates;
      });
    },
    //清空列表
    empty() {
      (this.param = {
        cityCode: localStorage.getItem('cityid'),
        countyCode: '',
        creDateCode: '',
        eduCode: '',
        industyCode: '',
        jobId: '',
        jobKey: '',
        pageIndex: 1,
        pageSize: 10,
        salRangeCode: '',
        welfareCodes: [],
        workExpCode: '',
      }),
        (this.tags = []);
      this.activeposition = '';
      this.activearea = null;
      this.activewelfare = [];
      this.getdatagridjobs();
    },
    //招聘列表
    getdatagridjobs() {
      datagridjobs(this.param).then((res) => {
        this.jobslist = res.data.result.list;
        this.total = res.data.result.totalCount;
        this.maximum = res.data.result.totalPage;
        this.param.pageIndex = res.data.result.totalPage;
      });
    },
    //职位名称
    getjobs() {
      getjob().then((res) => {
        this.positionlist = res.data.result.job;
      });
    },
    //删除tag
    handleClose(tag) {
      if (tag.kind == 'position') {
        this.param.jobId = '';
        this.param.jobKey = '';
        this.activeposition = '';
      }
      if (tag.kind == 'area') {
        this.param.countyCode = '';
        this.activearea = null;
      }
      if (tag.kind == 'welfare') {
        this.activewelfare.splice(this.tags.indexOf(tag), 1);
        this.param.welfareCodes.splice(this.tags.indexOf(tag), 1);
      }
      if (tag.kind == 'credate') {
        this.param.creDateCode = null;
      }
      if (tag.kind == 'sal') {
        this.param.salRangeCode = null;
      }
      if (tag.kind == 'work') {
        this.param.workExpCode = null;
      }
      if (tag.kind == 'edu') {
        this.param.eduCode = null;
      }
      if (tag.kind == 'industy') {
        this.param.industyCode = null;
      }
      this.tags.splice(this.tags.indexOf(tag), 1);
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    //添加tag
    addtag(id, name, kind) {
      const self = this;
      this.tags.forEach(function (item, i) {
        if (kind == item.kind) {
          self.tags.splice(i, 1);
        }
      });
      this.tags.push({ id: id, name: name, type: 'info', kind: kind });
    },
    //删除某个tag
    deltag(kind) {
      this.tags.splice(this.tags.indexOf(kind), 1);
    },
    //删除多个tag
    deltags(kind) {
      const self = this;
      let tages = [];
      this.tags.forEach(function (item, i) {
        if (kind != item.kind) {
          tages.push(item);
        }
      });
      this.tags = tages;
    },
    //职位
    clickposition(item) {
      if (item == '') {
        this.activeposition = '';
        this.param.jobId = '';
        this.param.jobKey = '';
        this.deltag('position');
      } else {
        this.addtag(item.id, item.name, 'position');
        this.activeposition = item.id;
        this.param.jobId = item.id;
        this.param.jobKey = item.name;
      }
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    //地点
    clickarea(item) {
      this.addtag(item.code, item.name, 'area');
      this.activearea = item.code;
      this.param.countyCode = item.code;
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    //福利
    clickwelfare(val) {
      const self = this;
      if (this.iswelfare(this.activewelfare, val.code) == false) {
        if (val.code == '') {
          this.activewelfare = [];
          this.param.welfareCodes = this.activewelfare;
          this.deltags('welfare');
        } else {
          this.activewelfare.push(val.code);
          this.param.welfareCodes = this.activewelfare;
          this.tags.push({
            id: val.code,
            name: val.name,
            type: 'info',
            kind: 'welfare',
          });
        }
      } else {
        this.activewelfare.forEach(function (item, i) {
          if (val.code == item) {
            self.activewelfare.splice(i, 1);
            self.param.welfareCodes = self.activewelfare;
            self.deltag(item);
          }
        });
      }
      this.param.pageIndex = 1;
      this.getdatagridjobs();
    },
    iswelfare(arr, n) {
      let flag = false;
      if (n == '' && arr.length == 0) {
        flag = true;
      } else {
        for (let i = 0; i < arr.length; i++) {
          //判断有没有这个数据
          if (arr[i] === n) {
            flag = true;
          }
        }
      }
      return flag;
    },
    numbersmall() {
      if (this.param.pageIndex === 2) {
        this.param.pageIndex = 1;
        this.getdatagridjobs();
      } else if (this.param.pageIndex > 2) {
        this.param.pageIndex--;
        this.getdatagridjobs();
      }
    },
    numberbig() {
      if (this.param.pageIndex === this.maximum - 1) {
        this.param.pageIndex++;
        this.getdatagridjobs();
      } else if (this.param.pageIndex < this.maximum - 1) {
        this.param.pageIndex++;
        this.getdatagridjobs();
      }
    },
    //查看招聘详情
    lookdetails(id) {
      let accessToken = this.accessToken;
      if (!accessToken) {
        return (this.iscontactTel = true);
      } else {
        this.$router.push({
          path: '/recruit/positiondetail',
          query: { id: id },
        });
      }
    },
    //申请
    apply() {},
    jobrecruit() {
      this.$router.push('/' + this.searchjinyingu);
    },
    getToken() {
      let accessToken = this.accessToken;
      if (!accessToken) {
        return (this.iscontactTel = true);
      } else {
        this.$router.push('/recruit/position');
      }
    },
    //header头部传过来关闭登录框的操作
    closeShow(val) {
      this.iscontactTel = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .rows .everything>span:nth-child(1){
		color: #ff552e;
	} */
.rows .everything .activeposition,
.rows .everything .activearea,
.rows .everything .activewelfare {
  color: #ff552e;
}
.rows .everything span {
  cursor: pointer;
}
.other .el-select {
  display: inline-block;
  width: 110px;
  margin-right: 3px;
}
>>> .other .el-select .el-input__inner,
>>> .other .el-select i {
  height: 28px;
  line-height: 28px;
}
>>> .rows .el-tag {
  height: 25px;
  display: inline-block;
  line-height: 25px;
  margin-right: 5px;
}
.containers {
  width: 1140px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.rows {
  display: inline-block;
  width: 1140px;
  margin: 0 auto;
  line-height: 30px;
}
.search {
  position: relative;
  margin-left: 350px;
}
.search .el-input {
  width: 400px;
  font-weight: 100;
  position: absolute;
  top: 16px;
  font-size: 12px;
}
>>> .search .el-input .el-input__inner {
  padding: 0 40px;
  height: 35px;
  border-radius: 0%;
}
.search img {
  width: 20px;
  height: 20px;
  position: relative;
  top: 33px;
  left: 10px;
}
.search .el-button {
  background-color: #e8e8e8;
  color: #757777;
  height: 35px;
  border-radius: 0px;
  position: relative;
  left: 380px;
  top: 27.5px;
}
.containers .recruit_position {
  color: #ee5500;
  background-color: #fff6ee;
  border: 2px solid #ee5500;
  margin-left: 230px;
}
.rows .position {
  color: #666666;
  float: left;
}
.rows .position_right {
  margin: 0 5px;
}
.rows .searchjinyingu {
  display: inline-block;
}
>>> .rows .searchjinyingu .el-input__inner,
>>> .rows .searchjinyingu i {
  height: 30px;
  line-height: 30px;
}
.rows .every span {
  color: black;
  margin-right: 12px;
  float: left;
}
.rows div,
.rows span {
  display: inline;
  position: relative;
  font-size: 12px;
}
.rows .every {
  margin-left: 20px;
  padding-right: 20px;
  width: 1000px;
  float: left;
  box-sizing: border-box;
}
.rows .el-select {
  height: 22px;
  border-radius: 0px;
}
.rightlittle {
  margin-right: 20px;
}
.rows .screen {
  height: 22px;
}
.rows button {
  height: 28px;
  border-radius: 0px;
  padding: 0px 6px;
}
.rows .allresult span {
  color: #ff552e;
  margin-left: 0px;
}
.rows .resumelist {
  background-color: #2e89ff;
  color: #fff;
  font-size: 22px;
  padding: 8px 30px;
  border-radius: 3px;
}
.borderdown {
  padding-bottom: 1px;
  border-bottom: 2px solid #2e89ff;
}
.rows .homepage {
  float: right;
}
.redfont {
  color: #ff552e !important;
}
.rows .homepage span {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 20;
  font-size: 16px;
  color: #ab9e9e;
}
.rows .homepage .numbersmall,
.rows .homepage .numberbig {
  cursor: pointer;
  font-size: 20px;
}
.bodyheight {
  min-height: 300px;
}
.bodyheight .bodyheight-norecruit {
  font-size: 14px;
  line-height: 60px;
  left: 45%;
  color: #909399;
}
.resumebegin {
  padding: 20px 0 10px 20px;
  position: relative;
}
.resumebegin .checkbox {
  width: 15px;
  height: 15px;
  float: left;
}
.resumebegin .checkbox + label {
  margin-right: 20px;
}
.safeinvestment {
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 10;
  max-width: 200px;
  max-height: 200px;
}
.resumebegin label {
  padding-left: 5px;
  float: left;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
}
.resumebegin > button {
  font-size: 12px;
  margin-right: 20px;
  float: left;
}
.resumebegin .el-select {
  width: 150px;
  outline: none;
  float: right;
  margin-right: 200px;
}
.resumebegin .resumebody {
  display: block;
  height: 150px;
  margin-top: 30px;
  border-bottom: 1px solid #edeef0;
}
.resumebegin .resumebody .body_font_first {
  width: 430px;
  float: left;
}
.resumebegin .resumebody .body_font_first .turnred:hover {
  color: #ff552e;
  cursor: pointer;
}
.resumebegin .resumebody .body_font_first span {
  font-size: 22px;
  margin-right: 4px;
  font-weight: 600;
}
.resumebegin .resumebody .body_font_first .place {
  display: inline-block;
  font-size: 22px;
  margin-right: 3px;
  font-weight: 600;
  max-width: 100px;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.resumebegin .resumebody .expect {
  display: block;
  font-size: 22px;
  color: #ff552e;
  margin: 5px 0;
  font-weight: 600;
}
.resumebegin .resumebody .turnred .position_line{
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.resumebegin .resumebody .turnred .position_first{
  display: inline-block;
  width: 330px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.resumebegin .resumebody .body_font_second {
  margin-top: 20px;
  margin-left: 50px;
  width: 350px;
  float: left;
  border-right: 1px solid #eeeeee;
}
.resumebegin .resumebody .body_font_second .company {
  display: block;
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.resumebegin .resumebody .body_font_second .position_second {
  width: 350px;
  color: #999999;
  display: inline-block;
}
.resumebegin .resumebody .body_font_second .position_second span {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.resumebegin .resumebody .body_font_second span {
  font-size: 15px;
  font-weight: 500;
  margin-right: 3px;
}
.resumebegin .resumebody .body_font_third {
  color: #ccd3e0;
  margin: 20px;
  font-size: 15px;
  width: 100px;
  float: left;
}
.resumebegin .resumebody .body_font_button .el-button {
  float: left;
  background-color: #2e93ff;
  margin: 20px;
  height: 35px;
  padding: 8px 30px;
  border: 0px;
  border-radius: 3px;
}
.resumebegin .resumebody .ablabel label {
  margin-top: 5px;
  margin-right: 5px;
  font-size: 13px;
  height: 25px;
  padding: 0px 5px;
  border-radius: 3px;
  color: #6c95ba;
  max-width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.center{
      width: 1140px;
    margin: 0 auto;
    background: #fff;
    padding: 0 30px;
    margin-top: 29px;
}
</style>
