import http from '@/utils/http'

//招聘筛选条件
export const screen = (params) =>{
    return http.get('/reetalentsinvite/gettalentsoptions?cityCode='+params)
}
//职位名称
export const getjob = () =>{
    return http.get('/reetalentsinvite/gettalentsoptions')
}

//招聘列表
export const datagridjobs = (params) =>{
    return http.post('/reetalentsinvite/getrecruitlist',params)
}
